import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';

import { AboutPageComponent } from './about-page.component';

@NgModule({
  imports: [
    BrowserModule,
    LazyLoadImagesModule
  ],
  declarations: [
    AboutPageComponent
  ]
})
export class AboutPageModule {}