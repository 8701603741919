import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { SharedModule, FooterComponent, HeaderComponent } from './shared';

import { AgmCoreModule } from '@agm/core';
import { HomePageModule } from './home-page/home-page.module';
import { EventPageModule } from './event-page/event-page.module';
import { AboutPageModule} from './about-page/about-page.module';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { SitePageComponent } from './site-page/site-page.component';

import { SurveyjsComponent} from './surveyjs.component';
import { EstimatePageComponent } from './estimate-page/estimate-page.component';
import { EmploymentPageComponent } from './employment-page/employment-page.component';
import { VisitPageComponent } from './visit-page/visit-page.component';
import { MicPageComponent } from './visit-page/mic-page/mic-page.component';
import { MazPageComponent } from './visit-page/maz-page/maz-page.component';
import { SinaloaPageComponent } from './visit-page/sinaloa-page/sinaloa-page.component';




@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ContactPageComponent,
    SitePageComponent,
    SurveyjsComponent,
    EstimatePageComponent,
    EmploymentPageComponent,
    VisitPageComponent,
    MicPageComponent,
    MazPageComponent,
    SinaloaPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    HomePageModule,
    EventPageModule,
    FormsModule,
    AboutPageModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBiiOF4LYZ5Lu2R8ffEw1Inl5xZnHeI9X8'
    })
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
