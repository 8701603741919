import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestsService } from '../requests.service';

@Component({
  selector: 'app-employment-page',
  templateUrl: './employment-page.component.html',
  styleUrls: ['./employment-page.component.css']
})
export class EmploymentPageComponent implements OnInit {
  
  
  myFile:File = null;
  contact = {name: '', email: '', tele: ''};
  firstResults;
  fileChange(files: any){
      
      this.myFile = files.item(0);
      document.getElementById("fileLbl").innerHTML = this.myFile.name;
      console.log(this.myFile);
  }
  

  constructor(private http: HttpClient, private requestService: RequestsService) { }

  ngOnInit() {
  }

  sendForm() {
    let _formData = new FormData();
    _formData.append("MyFile", this.myFile);
    // _formData.append("contactName", this.contact.name);
    // _formData.append("contactEmail", this.contact.email);
    // _formData.append("contactTele", this.contact.tele);
    _formData.append("contactDetail", JSON.stringify(this.contact));
    document.getElementById("fileLbl").innerHTML = "Seleccionar Archivo";
    //console.log(newC);
    console.log(_formData);
    this.httpReq( _formData);
    
    
  }
  //http://192.168.80.117/cms-mazatlanic-prueba/welcome_controller/sendVacanteFile

  httpReq(_formData) {
    var spin = document.getElementById('spinner');
    spin.style.display = "block";
    this.requestService.submitForm(_formData).subscribe(
      data => {
        this.firstResults = data;
      },
      err => {
        console.log(err);
      },
    () => {
      console.log(this.firstResults);
      console.log("Info Sent");
      spin.style.display = "none";
    }
    );
  }

  // httpReqq() {  
  //   this.contact['path'] = this.firstResults['file'];
  //   console.log(this.contact);
  //   // this.http.post('http://192.168.80.117/cms-mazatlanic-prueba/welcome_controller/sendVacante', this.contact).subscribe(
  //   //   res=> {
  //   //     console.log(res);
  //   //   },
  //   //   err => {
  //   //     console.log(err);
  //   //   }
  //   // );
  // }

}
