import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visit-page',
  templateUrl: './visit-page.component.html',
  styleUrls: ['./visit-page.component.css']
})
export class VisitPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
