import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    var navContainer = document.getElementById('m_nav_container');
    navContainer.style.display = "none";

  }

  
  // changeActive(newLink) {
  //   console.log(newLink);
  //     newLink.classList.add('active');
  //     // var old = document.getElementsByClassName('m_nav_item active');
  //     // old[0].classList.remove('active');
  // }
  
  HeaderController($scope, $location) {
    $scope.isActive = function (viewLocation) { 
      return viewLocation === $location.path();
    };
  }

  viewChange() {
    console.log("Clicked");
    var viewWidth = window.innerWidth;
    var toggleButton = document.getElementById('toggle_m_nav');
    var navContainer = document.getElementById('m_nav_container');
    var menuButton = document.getElementById('m_nav_menu');
    var menuButtonBars = document.getElementsByClassName('m_nav_ham');
    
    

    if(navContainer.style.display == 'none')
		{	
			console.log("hidden");
      navContainer.style.display = 'block';
      navContainer.classList.add('container_open');
      navContainer.style.zIndex = '2';
			
      document.getElementById('m_ham_1').classList.add('m_nav_ham_1_open');
      document.getElementById('m_ham_2').classList.add('m_nav_ham_2_open');
      document.getElementById('m_ham_3').classList.add('m_nav_ham_3_open');

		}
		else
		{
      navContainer.style.zIndex = "0";

      navContainer.classList.remove('container_open');
      navContainer.style.display = "none";
			
      document.getElementById('m_ham_1').classList.remove("m_nav_ham_1_open");
      document.getElementById('m_ham_2').classList.remove("m_nav_ham_2_open");
      document.getElementById('m_ham_3').classList.remove("m_nav_ham_3_open");
			

		}
  }

}
