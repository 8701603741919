import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maz-page',
  templateUrl: './maz-page.component.html',
  styleUrls: ['./maz-page.component.css']
})
export class MazPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
