import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { HostListener } from '@angular/core';
import { RequestsService } from '../requests.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  public mainEvent;
  public imgEventsRow1 = [];
  public imgEventsRow2 = [];

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    var modal = document.getElementById('myModal');
    if (modal.style.display == "block") {
      if(event.key == "Escape") {
        this.close();
      }
    }
  }


  constructor(private requestService: RequestsService) {}

  ngOnInit() {
    this.requestService.getMainEvents().subscribe(data => {
      for(let i in data['mainImgEvents']){
        if(data['mainImgEvents'][i].isMain=='1'){
          this.mainEvent=data['mainImgEvents'][i]['src'];
        }else{
          if(this.imgEventsRow1.length<2){
            this.imgEventsRow1.push(data['mainImgEvents'][i]);
          }else{
            this.imgEventsRow2.push(data['mainImgEvents'][i]);
          }
        }
      }
      console.log(this.imgEventsRow2);
    })
  }

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    autoHeight: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };

  public config1: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };

  public slides = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10'
  ];

  
  modalImg = document.getElementById("img01");
  displayed = false;

  imgModal(imgEle) {
    console.log(imgEle.src);
    var modal = document.getElementById('myModal');
    console.log(modal);
    modal.style.display = "block";
    document.getElementById("img01").setAttribute('src', imgEle.src);
    //this.modalImg.src = imgEle.src;
    var nav = document.getElementById('m_nav_menu');
    nav.style.display = "none";
    var navb = document.getElementById('m_nav_container');
    if (navb.style.display == "none") {
      this.displayed = false;
    } else {
      navb.style.display = "none";
      this.displayed = true;
    }
  }

  close() {
    var modal = document.getElementById('myModal');
    console.log(modal);
    modal.style.display = "none";
    var nav = document.getElementById('m_nav_menu');
    nav.style.display = "block";
    if (this.displayed == true) {
      var navb = document.getElementById('m_nav_container');
      navb.style.display = "block";
    }
  }

}
