import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from '../home-page/home-page.component';
import { ContactPageComponent } from '../contact-page/contact-page.component';
import { SitePageComponent } from '../site-page/site-page.component';
import { EstimatePageComponent} from '../estimate-page/estimate-page.component';
import { EmploymentPageComponent} from '../employment-page/employment-page.component';
import { EventPageComponent } from '../event-page/event-page.component';
import { VisitPageComponent } from '../visit-page/visit-page.component';
import { MicPageComponent } from '../visit-page/mic-page/mic-page.component';
import { MazPageComponent } from '../visit-page/maz-page/maz-page.component';
import { SinaloaPageComponent } from '../visit-page/sinaloa-page/sinaloa-page.component';
import { AboutPageComponent } from '../about-page/about-page.component';



 
const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard',  component:  HomePageComponent},
  { path: 'contact', component: ContactPageComponent },
  { path: 'site', component: SitePageComponent },
  { path: 'contact/estimate', component: EstimatePageComponent },
  { path: 'contact/employment', component: EmploymentPageComponent },
  { path: 'event', component: EventPageComponent },
  { path: 'visit', component: VisitPageComponent},
  { path: 'visit/MIC', component: MicPageComponent},
  { path: 'visit/mazatlan', component: MazPageComponent},
  { path: 'visit/sinaloa', component: SinaloaPageComponent},
  { path: 'about', component: AboutPageComponent},
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];
 
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}