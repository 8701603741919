import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  title: string = 'Mazatlan International Center';
  lat: number = 23.288977;
  lng: number = -106.456596;
  zoom: number = 12;

  constructor(private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  contact = {name: '', email: '', tele: '', detailles: ''};

  sendMail() {
    var spin = document.getElementById('spinner');
    spin.style.display = "block";
    console.log("Trying to Send");
    console.log(this.contact);
    this.http.post('http://mic.red-tel.net:8050/cms-mazatlanic-prueba/welcome_controller/sendMailWebPage', this.contact ).subscribe (
        res=> {
          console.log(res);
          this.contact.name = '';
          this.contact.email = '';
          this.contact.detailles = '';
          this.contact.tele='';
        },
        err => {
          console.log("Error occured "+ err);
        },
        () => {
          spin.style.display = "none";
        }
      );
  }


}
