import { ModuleWithProviders, NgModule } from '@angular/core';


import { HomePageComponent } from './home-page.component';
import { SharedModule } from '../shared';

import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};


@NgModule({
  imports: [
    SharedModule,
    SwiperModule
  ],
  declarations: [
    HomePageComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class HomePageModule {}