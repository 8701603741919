import { Component, OnInit } from '@angular/core';

import { RequestsService } from '../requests.service';
import { HostListener } from '@angular/core';




@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})


export class AboutPageComponent implements OnInit {

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    var modal = document.getElementById('myModal');
    if (modal.style.display == "block") {
      if(event.key == "Escape") {
        this.close();
      }
    }
  }

  
  constructor(private requestService: RequestsService) { }

  images = [];
  images1 = [];
  images2 = [];
  images3 = [];
  images4 = [];

  ngOnInit() {
    this.requestService.getGalIMG().subscribe(
      data => {
        console.log("IMG Links:" +JSON.stringify(data));
        for(var i in data) {
          this.images.push(data[i]);
        }
      }, 
      err => {
        console.log(err);
      },
      () => {
        //console.log(this.images);
        //Randomize Image Order
        this.images.sort(function(a, b){return 0.5 - Math.random()});
        //console.log(this.images);

        var len = this.images.length;
        var col1 = Math.floor(len/4);
        var col2 = Math.floor((len/4) *2);
        var col3 = Math.floor((len/4) * 3);
        
        for(var i = 0; i < col1; i++) {
          this.images1.push(this.images[i]);
          
        }
        for(var j = col1; j < col2; j++) {
          this.images2.push(this.images[j]);
        }
        for(var k = col2; k < col3; k++) {
          this.images3.push(this.images[k]);
        }
        for(var l = col3; l < len; l++) {
          this.images4.push(this.images[l]);
        }

      }
    )


    
    

    
  }



  modalImg = document.getElementById("img01");
  displayed = false;

  imgModal(imgEle) {
    //console.log(imgEle.src);
    var modal = document.getElementById('myModal');
    //console.log(modal);
    modal.style.display = "block";
    document.getElementById("img01").setAttribute('src', imgEle.src);
    //this.modalImg.src = imgEle.src;
    var nav = document.getElementById('m_nav_menu');
    nav.style.display = "none";
    var navb = document.getElementById('m_nav_container');
    if (navb.style.display == "none") {
      this.displayed = false;
    } else {
      navb.style.display = "none";
      this.displayed = true;
    }
  }

  close() {
    var modal = document.getElementById('myModal');
    //console.log(modal);
    modal.style.display = "none";
    var nav = document.getElementById('m_nav_menu');
    nav.style.display = "block";
    if (this.displayed == true) {
      var navb = document.getElementById('m_nav_container');
      navb.style.display = "block";
    }
  }
  

}


