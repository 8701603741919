import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestsService } from '../requests.service';

@Component({
  selector: 'app-estimate-page',
  templateUrl: './estimate-page.component.html',
  styleUrls: ['./estimate-page.component.css']
})
export class EstimatePageComponent implements OnInit {

  selection: string;
  today = new Date();
  contact = {name: '', email: '', tele: '', evento: '', eveOther: '', date: ''};
  constructor(private http: HttpClient, private requestService: RequestsService) { }

  ngOnInit() {
  }

  sendForm() {
    console.log("Trying to Send");
    var spin = document.getElementById('spinner');
    spin.style.display = "block";


    this.requestService.submitSol(this.contact).subscribe(
      data => {

      },
      err=> {
        console.log(err);
      },
      () => {
          console.log("Info Sent");
          spin.style.display = "none";
      }
    );

    console.log(this.contact);
  }

}
