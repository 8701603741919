import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(private http: HttpClient) { }

  private testURL = "http://mazatlaninternationalcenter.com/index.php/webhooks/sendVacanteFile";

  submitForm(obj: any): Observable<Response> {
    return this.http.post<any>(this.testURL, obj);

  }

  private solURL = "http://mazatlaninternationalcenter.com/index.php/webhooks/sendPresupuesto";

  submitSol(obj: any): Observable<Response> {
    return this.http.post<any>(this.solURL, obj);

  }

  private galEveURL = "http://mazatlaninternationalcenter.com/index.php/webhooks/getPrincipalEvents";
  getGalEve(): Observable<any> {
    return this.http.get<any>(this.galEveURL);

  }

  private allEveURL = "http://mazatlaninternationalcenter.com/index.php/webhooks/getPublicEvents";
  getAllEve(): Observable<any> {
    return this.http.get<any>(this.allEveURL);

  }


  private galIMG = "http://mazatlaninternationalcenter.com/index.php/webhooks/getGallery";
  getGalIMG(): Observable<any> {
    return this.http.get<any>(this.galIMG);

  }

  private gal64 = "http://mazatlaninternationalcenter.com/index.php/webhooks/getImgGallery"
  getGal64(url: any): Observable<any> {
    return this.http.post<any>(this.gal64, url);
  }

  private mainEvents = "http://mazatlaninternationalcenter.com/index.php/webhooks/getMainEventsImg";
  getMainEvents(): Observable<any> {
    return this.http.get<any>(this.mainEvents);
  }
}
