import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { HttpClient } from '@angular/common/http';
import { RequestsService } from '../requests.service';
import { Event } from '../../domain/index';


@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css']
})
export class EventPageComponent implements OnInit {

  public event = new Event();
  public event1 = new Event();
  slides: Event[] = [];
  slides1: Event[] = [];
  public months = [];
  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  
  
  constructor(private requestService: RequestsService) { }

  ngOnInit() {
      this.requestService.getGalEve().subscribe(data => {
        console.log("Featured Events:" +JSON.stringify(data));
        for(let i in data['events']) {
          console.log(data[i]);
          
            this.event.idEvent = data['events'][i].eventId;
            this.event.eventName = data['events'][i].evenName;
            this.event.dateEvent = data['events'][i].dateEvent;
            this.event.imgLink = data['events'][i].imgLink;
            console.log(this.event);
            this.slides.push(this.event);
            this.event = new Event();
          
        }

      })

      this.requestService.getAllEve().subscribe(data => {
        //console.log("All Event:" +JSON.stringify(data));
        for(let i in data['events']) {
          this.event1.idEvent = data['events'][i].eventId;
          this.event1.eventName = data['events'][i].eventName;
          this.event1.dateEvent = data['events'][i].eventBegin;
          var date1 = data['events'][i].eventBegin;
          console.log(data['events'][i].eventBegin);
          //console.log(newDate);
          //console.log(newDate.getDate());
          if (date1.charAt(date1.length - 1) == "1"){
            var newDate = new Date(data['events'][i].eventBegin);
            var m = newDate.getMonth() + 1;
            var y = newDate.getFullYear();
          } else {
            var newDate = new Date(data['events'][i].eventBegin);
            //console.log(newDate);
            var m = newDate.getMonth();
            var y = newDate.getFullYear();
          }
          var my = m+"-"+y;
          this.event1.dateShort = my;
          //console.log(my);
          var num = this.months.indexOf(my)
          if (num > -1) {

          } else {
            this.months.push(my);
          }

          this.slides1.push(this.event1);
          this.event1 = new Event();

        }
        this.months = this.months.sort(function(a,b) {
          a = a.split("-");
          b = b.split("-");
          return (new Date(a[1], a[0], 1) as any) - (new Date(b[1], b[0], 1) as any)
        });
        console.log(this.months);
        //console.log(this.slides1[0]);
        this.slides1 = this.slides1.sort(function(a,b) {
          return (new Date(a.dateEvent) as any) - (new Date(b.dateEvent) as any)
        });
        
       })

  }

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2,
    centeredSlides: true,
    //autoHeight: true,
    // slidesPerColumn: 2,
    spaceBetween: 30,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    loop: false,
    grabCursor: true,
    freeMode: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        initialSlide: 1
      }
    }
  };

  checkMonth(month) {
      //console.log(month);
      return this.slides1.filter(x => x.dateShort == month);
      
  }

  convertDate(date_str) {
    var temp_date = date_str.split("-");
    return temp_date[2] + " " + this.monthNames[Number(temp_date[1]) - 1] + " " + temp_date[0];
  }

  changeTxt() {
    //console.log(document.getElementById("hideBtn").innerText);
    var elem = document.getElementById("hideBtn");
    if (elem.innerText =="Ocultar Eventos") elem.innerText = "Ver todos los Eventos";
    else elem.innerText = "Ocultar Eventos";
  }

}
